<template>
	<transition :duration="600" @after-enter="signalShown" @after-leave="signalHidden">
		<div
			v-show="isOpen"
			:class="{
				'modal': true,
				[ `modal--${ name.replace( '/', '-' ) }` ]: true,
				'is-large': isLarge,
				'with-close': ! noClose,
				'align-center': alignCenter,
				immediate,
			}"
			:style="{ zIndex }"
			tabindex="-1"
			@click="handleContentClicks"
			@keyup.esc="maybeClose"
		>
			<div class="modal__frame" @click.self="maybeClose">
				<div class="modal__body">
					<BaseButton
						v-if="! noClose"
						ref="close"
						name="close"
						:location="name"
						is-large
						icon-only
						class="modal__close"
						@click="close"
					>
						<BaseIcon type="back" text="close" />
						<ScreenReaderText>Close</ScreenReaderText>
					</BaseButton>

					<slot />
				</div>
				<transition name="fade">
					<span v-if="showIndicator" class="scroll-indicator">More Activities</span>
				</transition>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import EventBus from '../utilities/event-bus';

const MODAL_ZINDEX = 10;

export default {
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			required: true,
		},
		isLarge: Boolean,
		noClose: Boolean,
		autoShow: Boolean,
		alignCenter: Boolean,
		withIndicator: Boolean,
	},
	model: {
		prop: 'isOpen',
		event: 'close',
	},
	data() {
		return {
			immediate: this.autoShow,
			showIndicator: this.withIndicator,
		};
	},
	computed: mapState( {
		isOpen( state ) {
			return state.activeModals.includes( this.name );
		},
		zIndex( state ) {
			return MODAL_ZINDEX + state.activeModals.indexOf( this.name );
		},
	} ),
	methods: {
		...mapMutations( {
			close( commit ) {
				this.$emit( 'close' );
				commit( 'hideModal', this.name );
			},
		} ),

		maybeClose() {
			if ( ! this.noClose ) {
				this.close();
			}
		},

		signalShown() {
			EventBus.$emit( `shown:${ this.name }` );
		},
		signalHidden() {
			EventBus.$emit( `hidden:${ this.name }` );
		},

		handleContentClicks( event ) {
			if ( event.defaultPrevented ) {
				return;
			}

			const element = event.target;

			// If it's a link with a relative URL, treat it as a modal link
			if ( element.nodeName === 'A' && element.href.indexOf( window.location.origin ) === 0 ) {
				event.preventDefault();
				const target = element.href.replace( window.location.origin + '/', '' );
				this.showModal( target );
			}
		},
	},
	watch: {
		isOpen( isOpen ) {
			if ( isOpen ) {
				this.$emit( 'show' );
				this.$nextTick( () => this.$el.focus() );
			} else {
				this.$el.blur();
			}
		},
	},
	mounted() {
		if ( this.autoShow ) {
			this.showModal( this.name );
		}

		const frame = this.$el.querySelector( '.modal__frame' );

		const removeIndicator = ( event ) => {
			if ( event.target.scrollTop >= 100 ) {
				this.showIndicator = false;
				frame.removeEventListener( 'scroll', removeIndicator );
			}
		};

		frame.addEventListener( 'scroll', removeIndicator );
	},
	provide() {
		return {
			parentView: this.name,
		};
	},
};
</script>
