<template>
	<BaseScreen name="mockup">
		<h1>Subject Topic Goes Right Here</h1>
		<h2 class="is-style-title">Title</h2>

		<BaseAccordion title="Accordion">
			<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Blanditiis vero architecto distinctio nam odit ipsam quia. Error laborum saepe nobis soluta quisquam ratione optio fugiat doloremque reprehenderit, corporis culpa perspiciatis?</p>
		</BaseAccordion>

		<BaseButton @click="showModal( 'modal-short' )">Short Modal</BaseButton>
		<BaseButton @click="showModal( 'modal-tall' )">Tall Modal</BaseButton>

		<h2>Progress Indicator</h2>
		<ProgressIndicator :current="1" :total="7" :label="'Location Information'" />
		<ProgressIndicator :current="2" :total="7" :label="'Profile Questions'"/>
		<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>

		<h3>Heading</h3>
		<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea </p>

		<h4>Subheading</h4>
		<p>Lorem ipsum dolor sit amet, <a href="#">consetetur sadipscing elitr</a>, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea </p>

		<BaseButton is-primary is-large>
			Assess your risk
			<small><BaseIcon type="clock" /> approximately 60 seconds</small>
		</BaseButton>

		<div class="button-group">
			<BaseButton>Activity Risks</BaseButton>
			<BaseButton>Risk Factors</BaseButton>
		</div>

		<FieldPostal v-model="postal" />

		<p>Postal Code: "{{ postal }}"</p>

		<FieldSelect
			:options="binaryOptions"
			v-model="binary"
		/>

		<p>Answer: "{{ binary }}"</p>

		<FieldSelect
			:modal="true"
			:options="ageOptions"
			v-model="age"
		/>

		<p>Age: "{{ age }}"</p>

		<ScoreCard
			title="Risk"
			:score="3"
		/>

		<DualScoreCard
			title="Personal Risk"
			target="#personal-risk"
			icon="group"
			:score="3"
			:alt-score="2"
		/>

		<template #actions>
			<BackButton />

			<NextButton />
		</template>

		<BaseModal name="modal-short">
			<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minus ab eaque beatae ad libero praesentium iusto, saepe id, quisquam dolor corporis sunt? Sed voluptas architecto vitae porro quas beatae delectus.</p>
		</BaseModal>
		<BaseModal name="modal-tall">
			<p v-for="n in 10" :key="n">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minus ab eaque beatae ad libero praesentium iusto, saepe id, quisquam dolor corporis sunt? Sed voluptas architecto vitae porro quas beatae delectus.</p>
		</BaseModal>
	</BaseScreen>
</template>

<script>
import BaseAccordion from './BaseAccordion';
import ProgressIndicator from './ProgressIndicator';
import BackButton from './BackButton';
import NextButton from './NextButton';
import FieldPostal from './FieldPostal';
import FieldSelect from './FieldSelect';
import ScoreCard from './ScoreCard';
import DualScoreCard from './DualScoreCard';

export default {
	data() {
		return {
			postal: '',
			binary: '',
			binaryOptions: [
				{
					name: 'yes',
					label: 'Yes',
				},
				{
					name: 'no',
					label: 'No',
				},
			],
			age: '',
			ageOptions: [
				{
					name: '18-24',
					label: '18-24',
				},
				{
					name: '25-34',
					label: '25-34',
				},
				{
					name: '35-44',
					label: '35-44',
				},
				{
					name: '45-54',
					label: '45-54',
				},
				{
					name: '55-64',
					label: '55-64',
				},
				{
					name: '65+',
					label: '65+',
				},
			],
		};
	},
	components: {
		BaseAccordion,
		ProgressIndicator,
		BackButton,
		NextButton,
		FieldPostal,
		FieldSelect,
		ScoreCard,
		DualScoreCard,
	},
};
</script>
