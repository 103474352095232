export default {
	install( Vue, translations ) {
		Vue.prototype.$l10n = function( string, ...values ) {
			string = translations[ string ] || string;

			if ( values.length > 0 ) {
				string = string.replace( /%(\d+)%/g, function( match, number ) {
					number = parseInt( number ) - 1;
					return values[ number ] || match;
				} );
			}

			return string;
		};
	},
};
