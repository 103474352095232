<template>
	<transition name="fade">
		<BaseScreen name="home">
			<template #header>
				<div class="home__header__poster">
					<span class="starburst">Updated for spring</span>
				</div>
			</template>

			<h1>Ontario COVID <br> Activity Risk Assessment</h1>

			<BaseButton is-primary is-large @click="start">
				Start Risk Assessment
				<small><BaseIcon type="clock" /> approximately 60 seconds</small>
			</BaseButton>

			<BaseButton is-large @click="$router.push('/guidance')">
				General Guidance
			</BaseButton>

			<p>
				<ModalLink target="about">About</ModalLink>
				{{ ' | ' }}
				<ModalLink target="privacy-policy">Privacy Policy</ModalLink>
				{{ ' | ' }}
				<ModalLink target="terms-of-use">Terms & Conditions</ModalLink>
			</p>

			<TheDisclaimerModal screen="home" @confirm="confirm" />

			<template #footer>
				<LogoLinks class="home__footer__logos" />
				<p class="home__footer__links">
					<ModalLink target="privacy-guarantee" class="privacy-badge">Privacy Guarantee</ModalLink>
				</p>
			</template>
		</BaseScreen>
	</transition>
</template>

<script>
import ModalLink from './ModalLink';
import LogoLinks from './LogoLinks';
import TheDisclaimerModal from './TheDisclaimerModal';

import withDisclaimer from '../utilities/start';
const methods = withDisclaimer( 'home' );

export default {
	methods,
	components: {
		ModalLink,
		LogoLinks,
		TheDisclaimerModal,
	},
};
</script>
