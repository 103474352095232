<template>
	<div>
		<button type="button" class="select-button" v-if="hasModal" @click="showModal( 'options' )">
			<span class="select-button__content">{{ currentLabel }}</span>
			<span class="select-button__icon"></span>

			<Portal to="modals">
				<BaseModal :name="`${ name }-options`">
					<OptionButtonGroup
						:layout="layout"
						:options="options"
						:disabled="disabled"
						v-model="input"
						is-vertical
					/>
				</BaseModal>
			</Portal>
		</button>

		<OptionButtonGroup
			v-else
			:layout="layout"
			:options="options"
			:disabled="disabled"
			v-model="input"
		/>
	</div>
</template>

<script>
import OptionButtonGroup from './OptionButtonGroup';

export default {
	inheritAttrs: false,
	props: {
		name: String,
		layout: String,
		labels: Object,
		options: {
			type: Array,
			required: true,
		},
		optionGroups: Array,
		value: {
			type: String,
			required: true,
		},
		disabled: Boolean,
	},
	computed: {
		hasModal() {
			return this.layout === 'modal';
		},
		input: {
			get() {
				return this.value;
			},
			set( value ) {
				this.hideModal( `${ name }-options` );
				this.$emit( 'input', value );
			},
		},
		currentLabel() {
			const current = this.options.find( ( { name } ) => name === this.value ) || {};
			return current.label || current.name || this.value || 'Select One';
		},
	},
	components: {
		OptionButtonGroup,
	},
};
</script>
