<template>
	<BaseModal :name="`${ screen }/disclaimer`" is-large>
		<div class="modal__text" v-html="text" />

		<div class="spacer" />

		<div class="text-center">
			<p>
				<label for="understand" class="checkfield">
					<input type="checkbox" id="understand" v-model="acceptDisclaimer" />
					I understand
				</label>
			</p>

			<BaseButton is-primary is-large @click="$emit( 'confirm' )" :disabled="! acceptDisclaimer">Okay</BaseButton>

			<p class="ontario">Currently available for Ontario use only</p>
		</div>
	</BaseModal>
</template>

<script>
import text from '../assets/docs/disclaimer.md';

export default {
	props: {
		screen: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			text,
		};
	},
	computed: {
		acceptDisclaimer: {
			get() {
				return this.$store.state.acceptMainDisclaimer;
			},
			set( value ) {
				this.$store.commit( 'acceptMainDisclaimer', value );
			},
		},
	},
};
</script>
