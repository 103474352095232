import 'whatwg-fetch';
import { fsa_whitelist } from './config';

const FSA_LOOKUP_URL = process.env.VUE_APP_FSA_LOOKUP_URL || '';

const cache = {};
const CACHE_TIMEOUT = 1000 * 60 * 60;

let fsaWhitelist = fsa_whitelist || 'A-Z';
if ( typeof fsaWhitelist === 'string' ) {
	fsaWhitelist = new RegExp( fsaWhitelist );
}

function sanitize( value ) {
	return value.toUpperCase().replace( /[^A-Z0-9]/g, '' ).substr( 0, 3 );
}

function validate( value ) {
	if ( typeof value !== 'string' ) {
		return false;
	}

	if ( value.length !== 3 ) {
		return false;
	}

	if ( fsaWhitelist instanceof RegExp ) {
		return !! value.match( fsaWhitelist );
	}

	return fsaWhitelist.includes( value );
}

export default async function( fsa ) {
	fsa = sanitize( fsa );

	// Check if cached and not expired
	if ( cache[ fsa ] && Date.now() - cache[ fsa ] < CACHE_TIMEOUT ) {
		return Promise.resolve( cache[ fsa ].data );
	}

	return window
		.fetch(
			`${ FSA_LOOKUP_URL }${ fsa }`,
			{ method: 'GET' },
		)
		.then( response => response.json() )
		.then( ( data ) => {
			// Cache with current time
			cache[ fsa ] = { date: Date.now(), data };

			return data;
		} )
		.catch( () => {} );
}

export { sanitize, validate };
