<template functional>
	<span :class="`icon icon--${ props.type }`">
		<ScreenReaderText v-if="text">{{ text }}</ScreenReaderText>
	</span>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			required: true,
		},
		text: String,
	},
};
</script>
