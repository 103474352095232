<template>
	<div :class="{
		'accordion': true,
		'is-open': isOpen,
	}">
		<Component :is="titleTag" class="accordion__header" @click="toggle">
			<span class="accordion__title">
				<slot name="title">{{ title }}</slot>
			</span>
			<button type="button" class="accordion__toggle">Show Details</button>
		</Component>
		<transition @enter="onEnter" @after-enter="onAfterEnter" @leave="onLeave" @after-leave="onAfterLeave">
			<div class="accordion__content" v-show="isOpen">
				<div v-if="content" class="accordion__content__inner" v-html="content" />
				<div v-else class="accordion__content__inner">
					<slot />
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		title: String,
		content: String,
		titleTag: {
			type: String,
			default: 'h3',
		},
	},
	data() {
		return {
			isOpen: false,
		};
	},
	methods: {
		toggle() {
			this.isOpen = ! this.isOpen;
			this.$emit( this.isOpen ? 'open' : 'close' );
		},

		onEnter( element ) {
			element.style.height = 'auto';
			const height = getComputedStyle( element ).height;

			element.style.height = 0;

			// Force repaint
			/* eslint-disable-next-line no-unused-expressions */
			getComputedStyle( element ).height;

			requestAnimationFrame( () => {
				element.style.height = height;
			} );
		},
		onAfterEnter( element ) {
			element.style.height = 'auto';
		},
		onLeave( element ) {
			element.style.height = 'auto';
			const height = getComputedStyle( element ).height;

			element.style.height = height;

			// Force repaint
			/* eslint-disable-next-line no-unused-expressions */
			getComputedStyle( element ).height;

			requestAnimationFrame( () => {
				element.style.height = 0;
			} );
		},
		onAfterLeave( element ) {
			element.style.height = 'auto';
		},
	},
	mounted() {
		window.addEventListener( 'hashchange', () => {
			if ( window.location.hash === `#${ this.$el.id }` ) {
				this.isOpen = true;
			}
		} );
	},
};
</script>
