<template>
	<Component :is="tag" class="screen-reader-text"><slot /></Component>
</template>

<script>
export default {
	props: {
		tag: {
			type: String,
			default: 'span',
		},
	},
};
</script>
