import { find } from 'lodash';

import { profile_questions, activity_questions, activities as theActivitites, activity_groups } from './config';

function addScoresToOptions( options, scores ) {
	if ( ! scores ) {
		return options;
	}

	return options.map( ( { name, label } ) => {
		const score = ( scores.find( s => s.answer === name ) || {} ).score;

		return { name, label, score };
	} );
}

// Prep profile and activity questions; expanding certain field types
function prepareQuestions( questions ) {
	return questions.map( ( { type, config = {}, scores, ...question } ) => {
		const fullConfig = { ...config };

		if ( type === 'yesno' ) {
			type = 'select';
			fullConfig.layout = 'minigrid';
			fullConfig.options = addScoresToOptions( [
				{ name: 'yes', label: 'Yes' },
				{ name: 'no', label: 'No' },
			], scores );
		}

		return {
			...question,
			type,
			config: fullConfig,
		};
	} );
}

const profileQuestions = prepareQuestions( profile_questions );
const activityQuestions = prepareQuestions( activity_questions );

// Prep activities; fleshing out questions
const activities = theActivitites.map( ( { questions, ...activity } ) => {
	questions = questions.map( ( { name, scores, ...settings } ) => {
		const question = find( activityQuestions, { name } );
		if ( ! question ) {
			return false;
		}

		const { config = {}, ...fullQuestion } = question;

		const fullConfig = JSON.parse( JSON.stringify( config ) );
		if ( fullConfig.options ) {
			fullConfig.options = addScoresToOptions( config.options, scores );
		}

		// If the question has a pre-requisite, check that the original exists
		if ( fullQuestion.prerequisite ) {
			const original = questions.find( q => q.name === fullQuestion.prerequisite.question );

			// If not, delete prerequisite
			if ( ! original ) {
				delete fullQuestion.prerequisite;
			} else
			// If it does but it's automatic, delete prerequisite
			// if the condition is met, otherwise skip outright
			if ( original.automatic ) {
				if ( ! fullQuestion.prerequisite.answers.includes( original.automatic.value ) ) {
					return false;
				}

				delete fullQuestion.prerequisite;
			}
		}

		return {
			config: fullConfig,
			...fullQuestion,
			...settings,
		};
	} ).filter( v => v );

	return {
		...activity,
		questions,
	};
} );

export {
	profileQuestions,
	activities,
	activity_groups,
};
