<template>
	<div
		:class="[ className(), `is-color-${ color }` ]"
		tabindex="-1"
	>
		<header :class="className( 'header' )">
			<transition name="fade">
				<BasePoster
					v-if="poster"
					:key="poster"
					:image="poster"
					:class="className( 'header', 'poster' )"
				/>
			</transition>
			<slot name="header" />
		</header>
		<div :class="className( 'body' )">
			<div :class="className( 'content' )">
				<slot />
			</div>
			<div v-if="$slots.actions" :class="className( 'actions' )">
				<slot name="actions" />
			</div>
		</div>
		<footer :class="className( 'footer' )">
			<slot name="footer">
				<LogoLinks :class="className( 'footer', 'logos' )" />
				<p :class="className( 'footer', 'links' )">
					<ModalLink target="privacy-policy">Privacy Policy</ModalLink>
					{{ ' | ' }}
					<ModalLink target="terms-of-use">Terms & Conditions</ModalLink>
				</p>
			</slot>
		</footer>
	</div>
</template>

<script>
import BasePoster from './BasePoster';
import LogoLinks from './LogoLinks';
import ModalLink from './ModalLink';

export default {
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			required: true,
		},
		blockName: String,
		poster: String,
		color: {
			type: String,
			default: 'default',
		},
	},
	methods: {
		className( ...elements ) {
			const block = this.blockName || this.name;

			const baseClass = [ 'screen', ...elements ];
			const blockClass = [ block, ...elements ];

			return baseClass.join( '__' ) + ' ' + blockClass.join( '__' );
		},
	},
	components: {
		BasePoster,
		LogoLinks,
		ModalLink,
	},
	provide() {
		return {
			parentView: this.name,
		};
	},
};
</script>
