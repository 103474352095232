<template>
	<div class="question-content">
		<h1>{{ title }}</h1>

		<p v-if="why" class="question__why">
			<BaseButton is-link @click="openWhy">Why does this matter?</BaseButton>

			<Portal to="modals">
				<BaseModal :name="`${ name }-why`">
					<h2 class="modal__title">Why are we asking this question?</h2>
					<h3 v-if="why.title" class="is-style-subtitle">{{ why.title }}</h3>
					<div v-html="why.content" />
					<p class="important"><strong>Please remember:</strong> we take your privacy very seriously and we do not collect or store any personal data. </p>
				</BaseModal>
			</Portal>
		</p>

		<p v-if="what" class="question__what">
			<BaseButton is-link @click="openWhat">What does this mean?</BaseButton>

			<Portal to="modals">
				<BaseModal :name="`${ name }-what`">
					<h2>{{ what.title }}</h2>
					<div v-html="what.content" />
					<p class="important"><strong>Please remember:</strong> we take your privacy very seriously and we do not collect or store any personal data. </p>
				</BaseModal>
			</Portal>
		</p>

		<div v-if="description" v-html="description" class="question__description" />

		<form action="" class="question__field" @submit.prevent="signalSubmit">
			<Component
				:is="fieldComponent"
				:name="name"
				v-bind="config"
				:disabled="disabled"
				:value="answer"
				@input="update"
			/>

			<p v-if="help" class="question__help">{{ help }}</p>

			<p v-if="withPrivacy"><ModalLink target="privacy-guarantee" class="privacy-badge">Privacy Guarantee</ModalLink></p>

			<transition name="fade">
				<div v-if="notice" :class="[ 'question__notice', notice.type ]" v-html="notice.text" />
			</transition>

			<div v-if="extra" class="question__extra" v-html="extra" />
		</form>

		<ActivityNotice v-if="type === 'activities' && answer" v-bind="current" />

		<div v-if="current && current.details" v-html="current.details" class="question__details" />
	</div>
</template>

<script>
import FieldSelect from './FieldSelect';
import FieldPostal from './FieldPostal';
import ActivitySelect from './ActivitySelect';
import ActivityNotice from './ActivityNotice';
import ModalLink from './ModalLink';

const FIELD_TYPE_COMPONENTS = {
	select: FieldSelect,
	postal: FieldPostal,
	activities: ActivitySelect,
};

export default {
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: String,
		type: {
			type: String,
			required: true,
		},
		answer: {
			type: String,
			required: true,
		},
		help: String,
		why: Object,
		what: Object,
		config: Object,
		notices: {
			type: Array,
			default: () => [],
		},
		extra: String,
		disabled: Boolean,
		withPrivacy: Boolean,
	},
	model: {
		prop: 'answer',
		event: 'update',
	},
	data() {
		return {
			fieldComponent: FIELD_TYPE_COMPONENTS[ this.type ] || FieldSelect,
		};
	},
	computed: {
		current() {
			if ( this.config?.options ) {
				return this.config.options.find( ( { name } ) => name === this.answer );
			}

			return null;
		},
		notice() {
			return this.notices.find( ( { answer } ) => answer === this.answer );
		},
	},
	methods: {
		openWhy() {
			this.showModal( `${ this.name }-why` );

			this.$gtag( 'event', 'why', {
				event_category: this.group,
				event_label: this.name,
			} );
		},
		openWhat() {
			this.showModal( `${ this.name }-what` );

			this.$gtag( 'event', 'what', {
				event_category: this.group,
				event_label: this.name,
			} );
		},
		update( value ) {
			this.$emit( 'update', value );
		},
		signalSubmit() {
			this.$emit( 'submit' );
		},
	},
	components: {
		ActivityNotice,
		ModalLink,
	},
};
</script>
