<template>
	<div>
		<div class="input-group">
			<input
				:id="elmId"
				:class="[ 'input__item', statusClass ]"
				type="text"
				name="fsa"
				placeholder="A1B"
				pattern="[A-Z][0-9][A-Z]"
				maxlength="3"
				:disabled="disabled"
				:value="input"
				@input="update"
			/>
			<div class="input__item dummy" aria-hidden="true">•••</div>
		</div>
		<label for="elmId" class="question__help">First three digits of the postal code</label>
	</div>
</template>

<script>
import { sanitize, validate } from '../utilities/fsa-lookup';

export default {
	inheritAttrs: false,
	props: {
		value: {
			type: String,
			required: true,
		},
		disabled: Boolean,
	},
	data() {
		return {
			input: this.value,
		};
	},
	computed: {
		isValid() {
			return validate( this.input );
		},

		statusClass() {
			if ( this.isValid ) {
				return 'is-valid';
			}

			if ( this.input.length === 3 ) {
				return 'is-invalid';
			}

			return '';
		},
	},
	methods: {
		update( event ) {
			this.input = sanitize( String( event.target.value ) );
			this.$emit( 'input', this.isValid ? this.input : '' );
		},
	},
};
</script>
