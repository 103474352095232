<template>
	<li :class="{
		'list-item': true,
		'is-followup': isFollowup,
		'is-automatic': isAutomatic,
	}">
		<p class="list-item__info">
			<span class="list-item__label" v-html="`${ question }:`" />
			<span class="list-item__value" v-html="answer" />
		</p>
		<p v-if="hasScore" class="list-item__actions">
			<span :class="[ 'flag', `is-risk-${ level.slug }` ]" :title="level.label">{{ level.flag }}</span>
		</p>
	</li>
</template>

<script>
import getLevel from '../utilities/levels';

export default {
	inheritAttrs: false,
	props: {
		question: {
			type: String,
			required: true,
		},
		answer: {
			type: String,
			required: true,
		},
		score: {
			type: [ Number, Boolean ],
			default: false,
		},
		hasScore: {
			type: Boolean,
			default: true,
		},
		isFollowup: Boolean,
		isAutomatic: Boolean,
	},
	computed: {
		level() {
			return getLevel( this.score );
		},
	},
	methods: {
		getLevel,
	},
};
</script>
