/* globals gtag */

export default {
	install( Vue, router ) {
		Vue.prototype.$gtag = function( ...args ) {
			gtag( ...args );
		};

		const sentEvents = [];
		Vue.prototype.$gtag.once = function( action, category, label, value = null ) {
			const key = [ action, category, label, value ].join( '|' );
			if ( ! sentEvents.includes( key ) ) {
				gtag( 'event', action, {
					event_category: category,
					event_label: label,
					value,
				} );
			}
		};

		router.afterEach( ( to ) => {
			gtag( 'event', 'page_view', {
				page_title: to.name,
				page_path: to.path,
			} );
		} );
	},
};
