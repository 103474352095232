import EventBus from './event-bus';

export default {
	install( Vue, store ) {
		Vue.mixin( {
			methods: {
				showModal( name ) {
					// skip if already open
					if ( store.state.activeModals.includes( name ) ) {
						return Promise.resolve();
					}

					return new Promise( ( resolve, reject ) => {
						const timeout = setTimeout( () => reject(), 1000 );

						EventBus.$on( `shown:${ name }`, () => {
							clearTimeout( timeout );
							resolve();
						} );

						store.commit( 'showModal', name );
					} );
				},
				hideModal( name ) {
					// skip if already open
					if ( ! store.state.activeModals.includes( name ) ) {
						return Promise.resolve();
					}

					return new Promise( ( resolve, reject ) => {
						const timeout = setTimeout( () => reject(), 1000 );

						EventBus.$on( `hidden:${ name }`, () => {
							clearTimeout( timeout );
							resolve();
						} );

						store.commit( 'hideModal', name );
					} );
				},
			},
		} );
	},
};
