<template>
	<a :href="`#${ target }`" @click.prevent="showModal( target )"><slot /></a>
</template>

<script>
export default {
	props: {
		target: {
			type: String,
			required: true,
		},
	},
};
</script>
