import Vue from 'vue';
import Vuex from 'vuex';

import fsaLookup from './fsa-lookup';

Vue.use( Vuex );

export default new Vuex.Store( {
	state: {
		acceptMainDisclaimer: false,
		acceptResultDisclaimer: false,

		activeModals: [],

		answers: {},
	},
	getters: {
		getAnswers: state => group => ( state.answers[ group ] || {} ),

		getAnswer: state => ( group, question ) => {
			if ( state.answers[ group ] ) {
				return state.answers[ group ][ question ] || null;
			}

			return null;
		},
	},
	mutations: {
		acceptMainDisclaimer( state, value ) {
			state.acceptMainDisclaimer = !! value;
		},
		acceptResultDisclaimer( state, value ) {
			state.acceptResultDisclaimer = !! value;
		},

		showModal( state, name ) {
			if ( ! state.activeModals.includes( name ) ) {
				state.activeModals.push( name );
			}
		},
		hideModal( state, name ) {
			const activeModals = [ ...state.activeModals ];
			const index = activeModals.indexOf( name );
			if ( index >= 0 ) {
				activeModals.splice( index, 1 );
				state.activeModals = activeModals;
			}
		},

		updateAnswer( state, { group, question, answer } ) {
			const { answers } = state;

			answers[ group ] = answers[ group ] || {};
			answers[ group ][ question ] = answer;

			state.answers = { ...answers };
		},

		setSession( state, value ) {
			state.session = value;
		},

		reset( state ) {
			state.activeModals = [];
			state.answers = {};
		},
	},
	actions: {
		calculateAnswer( { commit, getters }, { group, question, value } ) {
			commit( 'updateAnswer', {
				group,
				question,
				answer: {
					value,
					score: false,
				},
			} );

			fsaLookup( value ).then( ( result ) => {
				if ( ! result ) {
					return;
				}

				const current = getters.getAnswer( group, question );
				if ( result.FSA === current.value ) {
					commit( 'updateAnswer', {
						group,
						question,
						answer: {
							value,
							score: result.Risk,
							stage: result.Province,
						},
					} );
				}
			} );
		},
	},
	modules: {
	},
} );
