var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._b({tag:"Component",class:{
		'button': ! _vm.isLink,
		'is-link': _vm.isLink,

		'is-style-primary': _vm.isPrimary,
		'is-style-large': _vm.isLarge,
		'has-icon-only': _vm.iconOnly,
	},attrs:{"type":_vm.tag === 'button' ? _vm.type : null},on:{"click":_vm.onClick}},'Component',_vm.attrs,false),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }