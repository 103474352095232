<template>
	<div :class="[
		'option-button__group',
		`is-layout-${ layout }`,
	]">
		<OptionButton
			v-for="option in options"
			:key="option.name"
			v-bind="option"
			:is-selected="option.name === value"
			:disabled="disabled"
			@select="update"
		/>
	</div>
</template>

<script>
import OptionButton from './OptionButton';

export default {
	inheritAttrs: false,
	props: {
		options: {
			type: Array,
			required: true,
		},
		value: {
			type: String,
			required: true,
		},
		layout: {
			type: String,
			default: 'default',
		},
		disabled: Boolean,
	},
	methods: {
		update( value ) {
			this.$emit( 'input', value );
		},
	},
	components: {
		OptionButton,
	},
};
</script>
