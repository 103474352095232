<template>
	<div id="app" :class="`current-screen--${ $route.name }`">
		<RouterView />
		<PortalTarget name="modals" multiple />

		<BaseModal name="about" is-large align-center>
			<div class="modal__text" v-html="about" />
		</BaseModal>
		<BaseModal name="disclaimer" is-large>
			<div class="modal__text" v-html="disclaimer" />
		</BaseModal>
		<BaseModal name="privacy-policy" is-large>
			<div class="modal__text" v-html="privacyPolicy" />
		</BaseModal>
		<BaseModal name="terms-of-use" is-large>
			<div class="modal__text" v-html="termsOfUse" />
		</BaseModal>
		<BaseModal name="privacy-guarantee" is-large no-close>
			<div class="modal__text" v-html="privacyGuarantee" />
			<BaseButton is-primary is-large @click="hideModal( 'privacy-guarantee' )">Okay</BaseButton>
		</BaseModal>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import about from './assets/docs/about.md';
import disclaimer from './assets/docs/disclaimer.md';
import privacyPolicy from './assets/docs/privacy-policy.md';
import termsOfUse from './assets/docs/terms-of-use.md';
import privacyGuarantee from './assets/docs/privacy-guarantee.md';

export default {
	name: 'App',
	data() {
		return {
			about,
			disclaimer,
			privacyPolicy,
			termsOfUse,
			privacyGuarantee,
		};
	},
	computed: {
		...mapState( [
			'currentScreen',
		] ),
	},
	provide() {
		return {
			parentView: false,
		};
	},
};
</script>
