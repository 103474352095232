const LEVELS = [
	{
		threshold: 4,
		slug: 'high',
		label: 'High',
		flag: 'High',
	},
	{
		threshold: 3,
		slug: 'med-high',
		label: 'Medium-High',
		flag: 'Med/High',
	},
	{
		threshold: 2,
		slug: 'medium',
		label: 'Medium',
		flag: 'Medium',
	},
	{
		threshold: 1,
		slug: 'low-med',
		label: 'Low-Medium',
		flag: 'Low/Med',
	},
	{
		threshold: 0,
		slug: 'low',
		label: 'Low',
		flag: 'Low',
	},
];

export default function( score ) {
	if ( typeof score !== 'number' ) {
		return { slug: 'unknown', label: 'Not Available', flag: 'N/A' };
	}

	return LEVELS.find( ( { threshold } ) => score >= threshold );
}

export const MINIMUM = 0;
export const MAXIMUM = 4;
