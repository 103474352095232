<template>
	<div :class="[ 'poster', `poster-${ image || $vnode.key }` ]">
		<img :src="require(`../assets/img/poster-${ image }.svg`)" alt="" />
	</div>
</template>

<script>
export default {
	props: {
		image: String,
	},
};
</script>
