<template>
	<div :class="[ 'meter-card', scoreClass ]">
		<div class="meter-card__color" :style="colorPosition" />
		<h3 class="meter-card__title">
			<BaseIcon v-if="icon" :type="icon" />
			{{ title }}
		</h3>
		<p class="meter-card__subtitle">{{ level.label }}</p>
		<div class="meter-card__dashboard">
			<div class="meter-card__arrow-wrapper" :style="{
				'--end-angle': arrowAngle,
			}">
				<div class="meter-card__arrow"></div>
			</div>
		</div>
		<a v-if="target" :href="target" class="button">What does this mean?</a>
	</div>
</template>

<script>
import { default as getLevel, MAXIMUM } from '../utilities/levels';

export default {
	inheritAttrs: false,
	props: {
		title: {
			type: String,
			required: true,
		},
		target: String,
		icon: String,
		score: {
			type: [ Number, Boolean ],
			required: true,
		},
	},
	computed: {
		level() {
			return getLevel( this.score );
		},
		scoreClass() {
			return `score-${ Math.round( this.score ) }`;
		},
		colorPosition() {
			const position = ( this.score / MAXIMUM ) * 100;

			return {
				backgroundPosition: `${ position }% 0`,
			};
		},
		arrowAngle() {
			const angle = ( this.score / MAXIMUM ) * 180;

			return `${ angle }deg`;
		},
	},
	methods: {
		getLevel,
	},
};
</script>
