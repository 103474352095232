<template>
	<button type="button"
		:class="{
			'option-button': true,
			'is-selected': isSelected,
		}"
		:value="name"
		:disabled="disabled"
		@click="select"
	>
		<span v-if="label" v-html="label" />
		<span v-else>{{ name }}</span>
	</button>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			required: true,
		},
		label: String,
		isSelected: Boolean,
		disabled: Boolean,
	},
	methods: {
		select() {
			this.$emit( 'select', this.name );
		},
	},
};
</script>
