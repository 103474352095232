<template>
	<div class="progress-bar">
		<div class="progress-bar__chunk">
			<span class="progress-bar__label">Step 1: Profile</span>
			<div class="progress-bar__meter">
				<div class="progress-bar__meter__fill" :style="{ width: profileProgress }">{{ profileProgress }}</div>
			</div>
		</div>
		<div class="progress-bar__chunk">
			<label :for="`${ elmId }-activity`" class="progress-bar__label">Step 2: Activity</label>
			<div class="progress-bar__meter">
				<div class="progress-bar__meter__fill" :style="{ width: activityProgress }">{{ activityProgress }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		type: {
			type: String,
			required: true,
		},
		current: {
			type: Number,
			required: true,
		},
		total: {
			type: Number,
			required: true,
		},
	},
	computed: {
		profileProgress() {
			return this.progress( 'profile', 100 );
		},
		activityProgress() {
			return this.progress( 'activity', 0 );
		},
	},
	methods: {
		progress( type, defaultVal ) {
			let current = defaultVal;
			let total = defaultVal;

			if ( this.type === type ) {
				current = this.current;
				total = this.total;
			}

			if ( ! total ) {
				return '0%';
			}

			return Math.round( ( current / total ) * 100 ) + '%';
		},
	},
};
</script>
