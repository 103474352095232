<template>
	<transition name="fade">
		<BaseScreen name="guidance">
			<template #header>
				<BaseButton
					is-large
					icon-only
					@click="$router.push( '/' )"
				>
					<BaseIcon type="back" text="Back" />
					<ScreenReaderText>Go Back</ScreenReaderText>
				</BaseButton>

				<AboutButton />
			</template>

			<h2>General Guidance</h2>

			<p>Welcome our General Guidance around Covid-19 Public Health Measures! Use these resources to prepare during the provincial summer re-opening. </p>

			<div class="guidance-grid">
				<ModalLink target="guidance/outdoor" class="guidance-card outdoor">
					Benefits of <br /> outdoor activites
				</ModalLink>
				<ModalLink target="guidance/2of3" class="guidance-card masking">
					The <br /> 2/3 Rule
				</ModalLink>
				<ModalLink target="guidance/3cs" class="guidance-card three-cs">
					Reduce <br /> the 3 C's
				</ModalLink>
				<ModalLink target="guidance/vaccines" class="guidance-card vaccines">
					Importance <br /> of Vaccines
				</ModalLink>
			</div>

			<BaseButton is-primary is-large @click="start">
				Start Risk Assessment
				<small><BaseIcon type="clock" /> approximately 60 seconds</small>
			</BaseButton>

			<TheDisclaimerModal screen="guidance" @confirm="confirm" />

			<BaseModal name="guidance/outdoor" class="guidance-modal outdoor">
				<h2>Benefits of <br> outdoor activities</h2>
				<p>COVID-19 spreads more easily indoors than outdoors. Studies show that people are more likely to be exposed to COVID-19 when they are closer than 6 feet apart from others for longer periods of time (CDC). When you're outside, fresh air is constantly moving, dispersing these droplets. So you're less likely to breathe in enough of the respiratory droplets containing the virus that causes COVID-19 to become infected if you haven’t had a COVID-19 vaccine (Mayo Clinic)</p>

				<p class="guidance-notice danger">You are <em>more likely</em> to be exposed to COVID-19 when you:</p>

				<div class="guidance-grid">
					<figure class="guidance-graphic danger">
						<img :src="require('@/assets/img/guidance/crowded-space.svg')" alt="">
						<figcaption>Attend crowded, poorly ventilated indoor events</figcaption>
					</figure>
					<figure class="guidance-graphic danger">
						<img :src="require('@/assets/img/guidance/close-contact.svg')" alt="">
						<figcaption>Have close contact with infected people</figcaption>
					</figure>
				</div>

				<p class="guidance-notice warning">You are <em>less likely</em> to be exposed to COVID-19 when you</p>

				<div class="guidance-grid">
					<figure class="guidance-graphic">
						<img :src="require('@/assets/img/guidance/outdoor-activities.svg')" alt="">
						<figcaption>Attend outdoor activities</figcaption>
					</figure>
					<figure class="guidance-graphic">
						<img :src="require('@/assets/img/guidance/indoor-distancing.svg')" alt="">
						<figcaption>Stay at least 6 feet apart</figcaption>
					</figure>
					<figure class="guidance-graphic">
						<img :src="require('@/assets/img/guidance/limit-exposure.svg')" alt="">
						<figcaption>Limit the amount of time spent with people who don’t live with you (CDC)</figcaption>
					</figure>
				</div>

				<h3>List of activities</h3>
				<div class="guidance-grid guidance-activities">
					<div class="guidance-activity">
						<h4 class="guidance-activity__title">Walk, run, wheelchair roll, or bike outdoors with members of your household</h4>
						<ul class="guidance-activity__list">
							<li class="guidance-activity__option">
								<span>Unvaccinated <br> <strong>Safest</strong></span>
							</li>
							<li class="guidance-activity__option">
								<span>Vaccinated <br> <strong>Safest</strong></span>
							</li>
						</ul>
					</div>
					<div class="guidance-activity">
						<h4 class="guidance-activity__title">Attend a small, outdoor gathering with fully vaccinated family and friends</h4>
						<ul class="guidance-activity__list">
							<li class="guidance-activity__option">
								<span>Unvaccinated <br> <strong>Safest</strong></span>
							</li>
							<li class="guidance-activity__option">
								<span>Vaccinated <br> <strong>Safest</strong></span>
							</li>
						</ul>
					</div>
					<div class="guidance-activity">
						<h4 class="guidance-activity__title">Attend a small, outdoor gathering with fully vaccinated and unvaccinated people, particularly in areas of substantial to high transmission</h4>
						<ul class="guidance-activity__list">
							<li class="guidance-activity__option safe">
								<span>Unvaccinated <br> Safe</span>
							</li>
							<li class="guidance-activity__option safest">
								<span>Vaccinated <br> <strong>Safest</strong></span>
							</li>
						</ul>
					</div>
					<div class="guidance-activity">
						<h4 class="guidance-activity__title">Dine at an outdoor restaurant with friends from multiple households</h4>
						<ul class="guidance-activity__list">
							<li class="guidance-activity__option warning">
								<span>Unvaccinated <br> <strong>Less Safe</strong></span>
							</li>
							<li class="guidance-activity__option safest">
								<span>Vaccinated <br> <strong>Safest</strong></span>
							</li>
						</ul>
					</div>
					<div class="guidance-activity">
						<h4 class="guidance-activity__title">Attend a crowded, outdoor event, like a live performance, parade, or sports event</h4>
						<ul class="guidance-activity__list">
							<li class="guidance-activity__option danger">
								<span>Unvaccinated <br> <strong>Least Safe</strong></span>
							</li>
							<li class="guidance-activity__option safe">
								<span>Vaccinated <br> <strong>Safest</strong></span>
							</li>
						</ul>
					</div>
				</div>
			</BaseModal>

			<BaseModal name="guidance/2of3" class="guidance-modal masking">
				<h2>The <br /> 2/3 Rule</h2>

				<p>Based on the guidance provided by The Ontario Science Advisory Table:</p>

				<div class="guidance-grid">
					<figure class="guidance-graphic">
						<figcaption>Outdoors + Distancing = <strong>No Masks Needed</strong></figcaption>
						<img :src="require('@/assets/img/guidance/2of3-distancing.svg')" alt="">
					</figure>
					<figure class="guidance-graphic">
						<figcaption>Outdoors + No Distancing = <strong>Masks Needed</strong></figcaption>
						<img :src="require('@/assets/img/guidance/2of3-no-distancing.svg')" alt="">
					</figure>
					<p class="guidance-grid__caption danger"><strong>Always wear masks and distance indoors</strong></p>
				</div>

				<p><strong>If you are not vaccinated, you should use the two-out-of-three rule of thumb with the following conditions: outdoors, distanced and masked. </strong></p>

				<ul class="ruled">
					<li>If you're outdoors and social distancing, you do not need to wear a mask.</li>
					<li>If you're outdoors but not social distancing, you should wear a mask.</li>
					<li>If you're social distancing, but you're not outdoors, you should wear a mask.</li>
				</ul>
			</BaseModal>

			<BaseModal name="guidance/3cs" class="guidance-modal three-cs">
				<h2>Reduce <br /> the 3 C's</h2>

				<p>The World Health Organization suggests avoiding the 3 C’s when it comes to limiting the spread of COVID-19. It is necessary to limit:</p>

				<div class="guidance-grid">
					<figure class="guidance-graphic danger">
						<img :src="require('@/assets/img/guidance/card-close.svg')" alt="">
						<figcaption><strong>Close physical contact</strong> as the risk of of spreading COVID-19 is increased with prolonged exposure with a distance of less than 6 feet</figcaption>
					</figure>
					<figure class="guidance-graphic danger">
						<img :src="require('@/assets/img/guidance/card-closed.svg')" alt="">
						<figcaption><strong>Closed indoor spaces</strong> as ventilation indoors is relatively poor in comparison to outdoor spaces, increasing the chances for viral droplets or aerosols to circulate the air anywhere from minutes to hours</figcaption>
					</figure>
					<figure class="guidance-graphic danger">
						<img :src="require('@/assets/img/guidance/card-crowded.svg')" alt="">
						<figcaption><strong>Crowded spaces</strong> as the opportunity to socially distance with many people in an area is reduced</figcaption>
					</figure>
					<figure class="guidance-graphic danger">
						<img :src="require('@/assets/img/guidance/venn.svg')" alt="">
						<figcaption><strong>The risk of COVID-19 incidence is higher when any combination of the 3 C’s overlap and is highest when they all intersect.</strong></figcaption>
					</figure>
				</div>
			</BaseModal>

			<BaseModal name="guidance/vaccines" class="guidance-modal vaccines">
				<h2>Importance <br /> of Vaccines</h2>

				<p>Evidence has demonstrated that authorized COVID-19 vaccines are both efficacious (in controlled clinical trials) and effective (in real-world observational studies) against symptomatic, laboratory-confirmed COVID-19, including severe forms of the disease. </p>

				<div class="guidance-grid">
					<img :src="require('@/assets/img/guidance/vaccination.svg')" alt="">
					<div>
						<p><strong>In general, people are considered fully vaccinated:</strong></p>
						<ul class="ruled">
							<li>2 weeks after their second dose in a 2-dose series, such as the Pfizer or Moderna vaccines, or</li>
							<li>2 weeks after a single-dose vaccine, such as Johnson & Johnson’s Janssen vaccine</li>
						</ul>
						<p class="danger"><strong>If you don’t meet these requirements, regardless of your age, you are NOT fully vaccinated. Keep taking all precautions until you are fully vaccinated.</strong></p>
					</div>
				</div>
			</BaseModal>
		</BaseScreen>
	</transition>
</template>

<script>
import ModalLink from './ModalLink';
import AboutButton from './AboutButton';
import TheDisclaimerModal from './TheDisclaimerModal';

import withDisclaimer from '../utilities/start';
const methods = withDisclaimer( 'guidance' );

export default {
	methods,
	components: {
		ModalLink,
		AboutButton,
		TheDisclaimerModal,
	},
};
</script>
