<template>
	<div :class="[ 'meter-card', scoreClass ]">
		<div class="meter-card__color" :style="colorPosition" />
		<h3 class="meter-card__title">
			<strong>Your Personal Risk For:</strong>
			<br /><em>{{ title }}</em>
		</h3>
		<p class="meter-card__subtitle">{{ level.label }}</p>
		<div class="meter-card__dashboard">
			<div class="meter-card__arrow-wrapper" :style="{
				'--end-angle': arrowAngle,
			}">
				<div class="meter-card__arrow"></div>
			</div>
			<div v-if="hasAltScore" class="meter-card__arrow-wrapper alternate" :style="{
				'--start-angle': arrowAngle,
				'--end-angle': altArrowAngle,
			}">
				<div class="meter-card__arrow"></div>
			</div>
		</div>
		<div class="meter-card__details">
			<p v-if="hasAltScore" class="meter-card__legend">
				<span class="meter-card__legend-item navy">Your current <br /> risk level</span>
				<span class="meter-card__legend-item blue">If you were <br /> fully vaccinated</span>
			</p>
			<a v-if="target" :href="target" class="button">What does this mean?</a>
		</div>
		<div class="meter-card__footer">
			<slot />
		</div>
	</div>
</template>

<script>
import { default as getLevel, MAXIMUM } from '../utilities/levels';

export default {
	inheritAttrs: false,
	props: {
		title: {
			type: String,
			required: true,
		},
		caption: {
			type: String,
			default: null,
		},
		target: String,
		icon: String,
		score: {
			type: [ Number, Boolean ],
			required: true,
		},
		altScore: {
			type: Number,
			default: null,
		},
	},
	computed: {
		hasAltScore() {
			return this.altScore !== null;
		},
		level() {
			return getLevel( this.score );
		},
		scoreClass() {
			let classname = `score-${ Math.round( this.score ) }`;
			if ( this.altScore ) {
				classname += ` alt-score-${ Math.round( this.score - this.altScore ) }`;
			}
			return classname;
		},
		colorPosition() {
			const position = ( this.score / MAXIMUM ) * 100;

			return {
				backgroundPosition: `${ position }% 0`,
			};
		},
		arrowAngle() {
			const angle = ( this.score / MAXIMUM ) * 180;

			return `${ angle }deg`;
		},
		altArrowAngle() {
			const angle = ( this.altScore / MAXIMUM ) * 180;

			return `${ angle }deg`;
		},
	},
	methods: {
		getLevel,
	},
};
</script>
