<template>
	<p v-if="notice" :class="{
		'restriction-notice': true,
		'warn': notice.warn,
	}">{{ notice.message }}</p>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			required: true,
		},
		restrictions: Array,
	},
	computed: {
		...mapGetters( [ 'getAnswer' ] ),

		notice() {
			if ( ! this.restrictions ) {
				return false;
			}

			const answer = this.getAnswer( this.name, 'location' ) || this.getAnswer( 'profile', 'location' );
			if ( ! answer ) {
				return false;
			}

			const match = this.restrictions.find( ( condition ) => {
				return condition.stage === answer.stage;
			} );

			return match;
		},
	},
};
</script>
