export default function( screen ) {
	return {
		start() {
			this.$gtag( 'event', 'start', {
				event_category: 'screen',
				event_label: screen,
			} );

			if ( this.$store.state.acceptMainDisclaimer ) {
				this.advance();
				return;
			}

			this.showModal( `${ screen }/disclaimer` );
		},

		confirm() {
			this.$gtag( 'event', 'accept', {
				event_category: 'disclaimer',
				event_label: screen,
			} );

			this.hideModal( `${ screen }/disclaimer` )
				.then( () => {
					this.advance();
				} );
		},

		advance() {
			this.$router.push( { name: 'profile.firstQuestion' } );
		},
	};
}
