<template>
	<figure :class="{
		'graphic-item': true,
		'is-selected': isSelected,
	}">
		<div :class="[ 'graphic-item__poster', `is-color-${ color }` ]" @click="openDetails">
			<BasePoster :image="poster" class="graphic-item__poster__image" />
			<button v-if="details" type="button" class="info-button" @click="openDetails">More Info</button>
		</div>

		<figcaption class="graphic-item__content">
			<h3 class="graphic-item__title">{{ title }}</h3>
			<BaseButton is-primary @click="select">Select</BaseButton>
		</figcaption>

		<Portal v-if="details" to="modals">
			<BaseModal :name="name" align-center>
				<h2 class="modal__title">Activity Details</h2>
				<div :class="[ 'graphic-item__poster', `is-color-${ color }` ]">
					<BasePoster :image="poster" class="graphic-item__poster__image" />
					<ActivityNotice :name="name" :restrictions="restrictions" />
				</div>
				<h3>{{ title }}</h3>
				<div v-if="details" v-html="details"></div>

				<BaseButton value="option.name" is-primary is-large @click="select">Select this activity</BaseButton>
			</BaseModal>
		</Portal>
	</figure>
</template>

<script>
import BasePoster from './BasePoster';
import ActivityNotice from './ActivityNotice';

export default {
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		poster: String,
		color: String,
		details: String,
		restrictions: Array,
		isSelected: Boolean,
	},
	methods: {
		openDetails() {
			this.showModal( this.name );

			this.$gtag( 'event', 'details', {
				event_category: 'activity',
				event_label: this.name,
			} );
		},
		select() {
			this.$emit( 'select', this.name );
			this.hideModal( this.name );
		},
	},
	components: {
		BasePoster,
		ActivityNotice,
	},
};
</script>
