import Vue from 'vue';
import VueRouter from 'vue-router';
import PortalVue from 'portal-vue';
import { forOwn } from 'lodash';

import App from './App.vue';
import ScreenReaderText from './components/ScreenReaderText.vue';
import BaseIcon from './components/BaseIcon.vue';
import BaseButton from './components/BaseButton.vue';
import BaseModal from './components/BaseModal.vue';
import BaseScreen from './components/BaseScreen.vue';

import { translations } from './utilities/config';

import store from './utilities/store';
import router from './utilities/router';
import modals from './utilities/modals';
import l10n from './utilities/l10n';
import gtag from './utilities/gtag';

Vue.config.productionTip = false;

Vue.use( PortalVue );
Vue.use( VueRouter );
Vue.use( modals, store );
Vue.use( l10n, translations );
Vue.use( gtag, router );

Vue.mixin( {
	data() {
		return {
			elmId: 'vnode' + this._uid,
		};
	},
} );

forOwn( {
	ScreenReaderText,
	BaseIcon,
	BaseButton,
	BaseModal,
	BaseScreen,
}, ( component, name ) => {
	Vue.component( name, component );
} );

import './assets/styles/app.scss';

new Vue( {
	store,
	router,
	render: h => h( App ),
} ).$mount( '#app' );
