import { profileQuestions, activities } from './library';

const questionsByGroup = Object.fromEntries( [
	[ 'profile', profileQuestions ],
	...activities.map( ( { name, questions } ) => [ name, questions ] ),
] );

/**
 * Find an item by name, returning it's index with it.
 *
 * @param { Array } list The list to search.
 * @param { string } name The name to match.
 *
 * @return { Array } The match + index.
 */
function lookupItem( list, name ) {
	for ( let i = 0; i < list.length; i++ ) {
		if ( list[ i ].name === name ) {
			return [ list[ i ], i ];
		}
	}

	return [ null, -1 ];
}

function isRegularQuestion( question ) {
	return ! question.prerequisite && ! question.automatic;
}

function lookupActivity( name ) {
	return activities.find( a => a.name === name );
}
function lookupQuestion( group, name ) {
	const questions = questionsByGroup[ group ];
	if ( ! questions ) {
		return false;
	}

	return questions.find( q => q.name === name );
}

lookupQuestion.byIndex = function( group, index ) {
	let questions = questionsByGroup[ group ];
	if ( ! questions ) {
		return false;
	}

	questions = questions.filter( isRegularQuestion );

	return questions[ index ];
};

lookupQuestion.withPlacement = function( group, name ) {
	let questions = questionsByGroup[ group ];
	if ( ! questions ) {
		return false;
	}

	questions = questions.filter( isRegularQuestion );

	const [ question, index ] = lookupItem( questions, name );
	// Get the total questions in the group, skipping those with pre-requisites
	const total = questions.length;

	return {
		question,
		index,
		total,
	};
};

lookupQuestion.nextEntry = function( group, name ) {
	let questions = questionsByGroup[ group ];
	if ( ! questions ) {
		return false;
	}

	questions = questions.filter( isRegularQuestion );
	const [ question, index ] = lookupItem( questions, name );

	if ( ! question ) {
		return false;
	}

	return questions[ index + 1 ] || false;
};

lookupQuestion.followup = function( group, question ) {
	const questions = questionsByGroup[ group ];
	if ( ! questions ) {
		return false;
	}

	const followup = questions.find( ( { prerequisite } ) => {
		if ( prerequisite ) {
			return prerequisite.question === question;
		}

		return false;
	} );

	if ( ! followup ) {
		return;
	}

	const { prerequisite, ...theQuestion } = followup;

	return {
		condition: prerequisite.answers,
		...theQuestion,
	};
};

export {
	questionsByGroup,
	lookupQuestion,
	lookupActivity,
};
