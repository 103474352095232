<template>
	<transition name="fade">
		<BaseScreen name="end" block-name="question" poster="app" color="yellow">
			<QuestionInterface
				:key="question.name"
				v-bind="question"
				:answer="feedback"
				@update="updateFeedback"
				@submit="logFeedback"
			/>

			<template #actions>
				<BaseButton
					is-large
					:disabled="! feedback"
					@click="newActivity"
				>
					New Activity
				</BaseButton>

				<BaseButton
					is-primary
					is-large
					:disabled="! feedback"
					@click="newProfile"
				>
					Start Over
					<BaseIcon type="restart" />
				</BaseButton>
			</template>
		</BaseScreen>
	</transition>
</template>

<script>
import QuestionInterface from './QuestionInterface';

export default {
	data() {
		return {
			feedback: '',
			question: {
				name: 'feedback',
				title: 'Thanks!',
				description: '<p>Was this tool helpful for you?</p>',
				type: 'select',
				config: {
					layout: 'minigrid',
					options: [
						{ name: 'yes', label: 'Yes' },
						{ name: 'no', label: 'No' },
					],
				},
			},
		};
	},
	methods: {
		updateFeedback( value ) {
			this.feedback = value;
		},
		logFeedback() {
			this.$gtag( 'event', 'answer', {
				event_category: 'feedback',
				event_label: this.feedback,
			} );
		},
		newActivity() {
			this.logFeedback();
			this.$router.push( { name: 'activities' } );
		},
		newProfile() {
			this.logFeedback();
			this.$store.commit( 'reset' );
			this.$router.push( { name: 'home' } );
		},
	},
	components: {
		QuestionInterface,
	},
};
</script>
