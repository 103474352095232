<template>
	<transition name="fade">
		<BaseScreen name="result" :class="{ 'in-view': acceptDisclaimer }">
			<template #header>
				<div class="result__header__top">
					<AboutButton />

					<LogoLinks color="navy" />
				</div>
			</template>

			<DualScoreCard
				:title="activityName"
				target="#personal-risk"
				icon="group"
				:score="adjustedScore"
				:alt-score="fullyVaxxedScore"
			>
				<p>{{ vaccinationNotice.text }}</p>
				<BaseButton :href="vaccinationNotice.link">
					{{ vaccinationNotice.action }}
				</BaseButton>
			</DualScoreCard>

			<div class="card-group">
				<a
					v-for="card in recommendationCards"
					:key="card.name"
					:href="`#${ card.name }`"
					:class="{
						'card': true,
						'is-major': card.isMajor === true,
						'status-success': card.isFollowed === true,
						'status-warning': card.isFollowed === false,
					}"
				>
					<div :class="[ 'card__graphic', `graphic-${ card.icon }`]" />
					<p class="card__title" v-html="card.title" />
				</a>
			</div>

			<h3 class="is-style-subtitle">Your Assessment Details</h3>

			<AnswerBreakdown
				id="personal-risk"
				title="Personal Risk Result"
				icon="group"
				:score="results.score"
				:answers="results.answers"
			>
				<p>This is your personal Covid-19 activity risk assessment based on the activity selected and your responses on how you plan to engage in this activity. This assessment informs you of the likelihood of contracting Covid-19 associated with this activity based on currently available evidence and recommendations.</p>
			</AnswerBreakdown>

			<h3 class="is-style-subtitle">Recommendations</h3>

			<BaseAccordion
				v-for="item in recommendationDetails"
				:key="item.name"
				titleTag="h4"
				:id="item.name"
				class="is-style-help"
				:content="item.details"
				@open="logRecommendation( item )"
			>
				<template #title>
					<div :class="[ 'accordion__icon', `graphic-${ item.icon }`]" />
					<span v-html="item.title" />
				</template>
			</BaseAccordion>

			<template #actions>
				<NextButton @click="finish">Finish</NextButton>
			</template>

			<BaseModal name="results/disclaimer" :auto-show="! acceptDisclaimer" no-close>
				<div class="modal__text" v-html="disclaimerText" />

				<div class="spacer" />

				<div class="text-center">
					<BaseButton is-primary is-large @click="acknowledge">I understand</BaseButton>

					<p class="ontario">Currently available for Ontario use only</p>
				</div>
			</BaseModal>
		</BaseScreen>
	</transition>
</template>

<script>
import { recommendations } from '../utilities/config';
import store from '../utilities/store';
import { questionsByGroup, lookupActivity } from '../utilities/lookup';

import disclaimerText from '../assets/docs/disclaimer-basic.md';

import AboutButton from './AboutButton';
import LogoLinks from './LogoLinks';
import DualScoreCard from './DualScoreCard';
import AnswerBreakdown from './AnswerBreakdown';
import NextButton from './NextButton';
import BaseAccordion from './BaseAccordion';

const HALF_VAXXED_ADJUST = 1;
const HALF_VAXXED_CAP = 2;
const FULL_VAXXED_ADJUST = 3;
const FULL_VAXXED_CAP = 1;

function calculateAnswers( group ) {
	if ( ! group ) {
		return {};
	}

	const questions = questionsByGroup[ group ];

	let count = 0;
	let total = 0;
	const answers = [];

	questions.forEach( ( question ) => {
		const answer = question.automatic || store.getters.getAnswer( group, question.name );

		if ( answer ) {
			if ( typeof answer.score === 'number' ) {
				count++;
				total += answer.score;
			}

			let option = null;
			if ( question.config?.options ) {
				option = question.config.options.find( o => o.name === answer.value );
			}

			answers.push( {
				question: question.label,
				answer: option ? ( option.title || option.label || option.name ) : answer.value,
				score: answer.score,
				isFollowup: !! question.prerequisite,
				isAutomatic: !! question.automatic,
			} );
		}
	} );

	return {
		score: total / count,
		answers,
	};
}

export default {
	data() {
		return {
			disclaimerText,
		};
	},
	computed: {
		currentActivity() {
			const answer = store.getters.getAnswer( 'app', 'activity' );

			return answer?.value;
		},

		activityName() {
			const activity = lookupActivity( this.currentActivity );

			return activity?.title || 'N/A';
		},

		acceptDisclaimer: {
			get() {
				return this.$store.state.acceptResultDisclaimer;
			},
			set( value ) {
				this.$store.commit( 'acceptResultDisclaimer', value );
			},
		},

		results() {
			const profile = calculateAnswers( 'profile' );
			const activity = calculateAnswers( this.currentActivity );

			return {
				score: ( profile.score + activity.score ) / 2,
				answers: [
					...profile.answers,
					{
						question: 'Activity',
						answer: this.activityName,
						hasScore: false,
					},
					...activity.answers,
				],
			};
		},

		adjustedScore() {
			const answer = store.getters.getAnswer( 'profile', 'vaccinated' );

			let score = this.results.score;
			let minimum = 1;
			switch ( answer.value ) {
				case 'yes-1':
					score = Math.min( score - HALF_VAXXED_ADJUST, HALF_VAXXED_CAP );
					minimum = 0.5;
					break;

				case 'yes-2':
					score = Math.min( score - FULL_VAXXED_ADJUST, FULL_VAXXED_CAP );
					minimum = 0;
					break;
			}

			return Math.max( score, minimum );
		},

		fullyVaxxedScore() {
			const answer = store.getters.getAnswer( 'profile', 'vaccinated' );

			if ( answer.value === 'yes-2' ) {
				return null;
			}

			return Math.max( Math.min( this.results.score - FULL_VAXXED_ADJUST, FULL_VAXXED_CAP ), 0 );
		},

		activityRecommendation() {
			const activity = lookupActivity( this.currentActivity );

			if ( ! activity.recommendations ) {
				return null;
			}

			return {
				name: 'risk-reduction',
				title: `<strong>Risk Reduction Strategies:</strong> ${ activity.title }`,
				icon: 'risks',
				details: activity.recommendations,
				isMajor: true,
				isFollowed: false,
			};
		},

		recommendationCards() {
			const cards = recommendations.filter( r => r.card ).map( ( { name, icon, card: { title, condition } } ) => {
				const answer = store.getters.getAnswer( this.currentActivity, condition.question );
				let isFollowed = null;
				if ( answer ) {
					isFollowed = answer && answer.value === condition.answer;
				}

				return {
					name,
					title,
					icon,
					isFollowed,
				};
			} );

			if ( this.activityRecommendation ) {
				cards.unshift( this.activityRecommendation );
			}

			return cards;
		},

		recommendationDetails() {
			const details = recommendations.filter( r => r.details );

			if ( this.activityRecommendation ) {
				details.unshift( this.activityRecommendation );
			}

			return details;
		},

		vaccinationNotice() {
			let text = 'Reduce your risk for this activity by receiving your first dose of the vaccine';
			let link = 'https://covid-19.ontario.ca/book-vaccine/';
			let action = 'Book a vaccine';

			const answer = store.getters.getAnswer( 'profile', 'vaccinated' );
			switch ( answer.value ) {
				case 'yes-1':
					text = 'Reduce your risk for this activity by receiving your second dose of the vaccine and protecting yourself with full immunity';
					break;

				case 'yes-2':
					text = 'Congratulations! By being fully vaccinated you have significantly lowered your risks around this activity';
					link = 'https://www.cdc.gov/coronavirus/2019-ncov/vaccines/vaccine-benefits.html?s_cid=10465:benefits%20of%20covid%2019%20vaccine:sem.ga:p:RG:GM:gen:PTN:FY21';
					action = 'Learn More';
					break;
			}

			return {
				text,
				link,
				action,
			};
		},
	},
	methods: {
		acknowledge() {
			this.acceptDisclaimer = true;
			this.hideModal( 'results/disclaimer' );

			this.$gtag( 'event', 'accept', {
				event_category: 'disclaimer',
				event_label: 'results',
			} );
		},

		logRecommendation( recommendation ) {
			this.$gtag.once( 'read', 'recommendation', recommendation.name );
		},

		finish() {
			this.$router.push( { name: 'end' } );
		},
	},
	components: {
		AboutButton,
		DualScoreCard,
		AnswerBreakdown,
		NextButton,
		LogoLinks,
		BaseAccordion,
	},
};
</script>
