<template>
	<div class="result__details">
		<div class="result__details__header">
			<BaseIcon :type="icon" />
			<h4>
				<span class="result__details__kicker">{{ title }}</span>
				<span class="result__details__title">{{ getLevel( score ).label }}</span>
			</h4>
		</div>

		<slot />

		<ul class="result-list">
			<AnswerBreakdownItem v-for="answer in answers" :key="answer.question" v-bind="answer" />
		</ul>
	</div>
</template>

<script>
import getLevel from '../utilities/levels';

import AnswerBreakdownItem from './AnswerBreakdownItem';

export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		icon: String,
		score: {
			type: [ Number, Boolean ],
			required: true,
		},
		answers: {
			type: Array,
			required: true,
		},
	},
	methods: {
		getLevel,
	},
	components: {
		AnswerBreakdownItem,
	},
};
</script>
