<template>
	<transition name="fade">
		<BaseScreen
			:name="question.name"
			block-name="question"
			:poster="poster"
			:color="color"
		>
			<template #header>
				<AboutButton />
				<ProgressIndicator v-if="progress" v-bind="progress" />
			</template>

			<transition name="fade" mode="out-in">
				<QuestionInterface
					:key="question.name"
					v-bind="question"
					:answer="answer"
					:with-privacy="type === 'profile'"
					@update="updateAnswer"
					@submit="submit"
				/>
			</transition>

			<template #actions>
				<BackButton />

				<NextButton
					:disabled="! answer"
					@click="submit"
				/>
			</template>

			<Portal v-if="followup" to="modals">
				<BaseModal :name="followup.name" class="question-modal" align-center no-close>
					<QuestionInterface
						:key="followup.name"
						v-bind="followup"
						:answer="followupAnswer"
						:with-privacy="type === 'profile'"
						@update="submitFollowup"
					/>
				</BaseModal>
			</Portal>
		</BaseScreen>
	</transition>
</template>

<script>
import AboutButton from './AboutButton';
import ProgressIndicator from './ProgressIndicator';
import QuestionInterface from './QuestionInterface';
import BackButton from './BackButton';
import NextButton from './NextButton';

export default {
	props: {
		type: {
			type: String,
			required: true,
		},
		group: {
			type: String,
			required: true,
		},
		question: {
			type: Object,
			required: true,
		},
		followup: Object,
		progress: Object,
	},
	data() {
		return {
			answer: this.getAnswer( this.question ),
			followupAnswer: this.getAnswer( this.followup ),
		};
	},
	computed: {
		poster() {
			return this.getQuestionOrOptionProp( 'poster' );
		},
		color() {
			return this.getQuestionOrOptionProp( 'color' );
		},
	},
	methods: {
		getAnswer( question ) {
			if ( ! question ) {
				return '';
			}

			let answer = this.$store.getters.getAnswer( this.group, question.name );

			if ( ! answer && question.inherits ) {
				answer = this.$store.getters.getAnswer( 'profile', question.name );
			}

			if ( ! answer ) {
				return '';
			}

			return answer.value;
		},

		getQuestionOrOptionProp( prop ) {
			let value = this.question[ prop ];

			if ( this.question.config?.options && this.answer ) {
				const selected = this.question.config.options.find( ( { name } ) => name === this.answer );

				if ( selected ) {
					value = selected[ prop ] || value;
				}
			}

			return value;
		},

		updateAnswer( value ) {
			this.answer = value;
		},

		submitAnswer( question, value ) {
			if ( question.type === 'postal' ) {
				this.$store.dispatch( 'calculateAnswer', {
					group: this.group,
					question: question.name,
					value,
				} );
			} else {
				const score = question.config.options.find( option => option.name === value ).score;

				this.$store.commit( 'updateAnswer', {
					group: this.group,
					question: question.name,
					answer: {
						value,
						score,
					},
				} );
			}

			if ( this.group === 'app' ) {
				this.$gtag( 'event', 'answer', {
					event_category: question.name,
					event_label: value,
				} );
			} else {
				this.$gtag( 'event', 'answer', {
					event_category: this.group,
					event_label: question.name,
				} );

				// Also log a separate event for answer value
				if ( this.type !== 'profile' && question.type !== 'postal' ) {
					this.$gtag( 'event', `answer:${ question.name }`, {
						event_category: this.group,
						event_label: value,
					} );
				}
			}
		},

		submit() {
			this.submitAnswer( this.question, this.answer );

			if ( this.followup && this.followup.condition.includes( this.answer ) ) {
				this.showModal( this.followup.name );
				return;
			}

			this.advance();
		},

		submitFollowup( value ) {
			this.followupAnswer = value;
			this.submitAnswer( this.followup, value );

			this.hideModal( this.followup.name )
				.then( () => {
					this.advance();
				} );
		},

		advance() {
			if ( this.type === 'activities' ) {
				return this.$router.push( {
					name: 'activity.firstQuestion',
					params: {
						group: this.answer,
					},
				} );
			}

			this.$router.push( {
				name: `${ this.type }.nextQuestion`,
				params: {
					group: this.group,
					question: this.question.name,
				},
			} );
		},
	},
	watch: {
		// reset answers when questions change
		question() {
			this.answer = this.getAnswer( this.question );
		},
		followup() {
			this.followupAnswer = this.getAnswer( this.followup );
		},
	},
	components: {
		AboutButton,
		ProgressIndicator,
		QuestionInterface,
		BackButton,
		NextButton,
	},
};
</script>
