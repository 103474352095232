const {
	fsa_whitelist,
	profile_questions = [],
	activity_questions = [],
	activity_groups,
	activities = [],
	recommendations = [],
	translations = {},
} = window.APP_CONFIG || {};

const languages = window.APP_LANGUAGES || [];

export {
	fsa_whitelist,
	profile_questions,
	activity_questions,
	activity_groups,
	activities,
	recommendations,
	translations,
	languages,
};
