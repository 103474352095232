<template>
	<div class="logos">
		<a href="https://dlsph.utoronto.ca/" target="_blank"><img :src="require(`../assets/img/logo-dlsph-${ color }.svg`)" alt="Dalla Lana School of Public Health"></a>
	</div>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			default: 'white',
		},
	},
};
</script>
