<template>
	<button type="button" class="select-button" @click="showModal( 'activities' )">
		<span class="select-button__content">{{ currentLabel }}</span>
		<span class="select-button__icon"></span>

		<Portal to="modals">
			<BaseModal name="activities" is-large with-indicator>
				<h2 class="modal__title">Select an Activity</h2>
				<div v-if="optionGroups">
					<div v-for="group in groupedOptions" :key="group.name" class="graphic-group">
						<BaseAccordion :title="group.title" :content="group.details" class="is-style-help" />
						<div class="graphic-list">
							<ActivitySelectItem
								v-for="option in group.options"
								:key="option.name"
								v-bind="option"
								:is-selected="option.name === value"
								@select="update"
							/>
						</div>
					</div>
				</div>
				<div v-else class="graphic-list">
					<ActivitySelectItem
						v-for="option in options"
						:key="option.name"
						v-bind="option"
						@select="update"
					/>
				</div>
			</BaseModal>
		</Portal>
	</button>
</template>

<script>
import BaseAccordion from './BaseAccordion';
import ActivitySelectItem from './ActivitySelectItem';

export default {
	inheritAttrs: false,
	props: {
		options: {
			type: Array,
			required: true,
		},
		optionGroups: Array,
		value: {
			type: String,
			required: true,
		},
	},
	data() {
		const groupedOptions = this.optionGroups.map( ( { name, ...info } ) => {
			return {
				name,
				...info,
				options: this.options.filter( ( { group } ) => group === name ),
			};
		} );

		return {
			groupedOptions,
		};
	},
	computed: {
		currentLabel() {
			const current = this.options.find( ( { name } ) => name === this.value ) || {};
			return current.title || 'Select One';
		},
	},
	methods: {
		update( value ) {
			this.hideModal( 'activities' );
			this.$emit( 'input', value );
		},
	},
	components: {
		BaseAccordion,
		ActivitySelectItem,
	},
};
</script>
