import VueRouter from 'vue-router';

import store from './store';
import { activities, activity_groups } from '../utilities/library';
import { lookupQuestion } from './lookup';

import TheMockup from '../components/TheMockup';
import TheHomeScreen from '../components/TheHomeScreen';
import QuestionScreen from '../components/QuestionScreen';
import TheEndScreen from '../components/TheEndScreen';
import TheResultScreen from '../components/TheResultScreen';
import TheGuidanceScreen from '../components/TheGuidanceScreen';

const BASE_URL = process.env.VUE_APP_BASE_URL || process.env.BASE_URL;

function getQuestionProps( route ) {
	const group = route.params.group || route.meta.group;
	const type = route.meta.type || group;

	const { question, index, total } = lookupQuestion.withPlacement( group, route.params.question );
	const followup = lookupQuestion.followup( group, question.name );

	return {
		type,
		group,
		question,
		followup,
		progress: {
			type,
			current: index + 1,
			total: total + ( group === 'profile' ? 0 : 1 ),
		},
	};
}

function redirectToFirstQuestion( to ) {
	const group = to.params.group || to.meta.group;
	const type = to.meta.type || group;

	const question = lookupQuestion.byIndex( group, 0 );

	return {
		name: `${ type }.question`,
		params: {
			group,
			question: question.name,
		},
	};
}

function redirectToNextQuestion( to ) {
	const group = to.params.group || to.meta.group;
	const type = to.meta.type || group;
	const question = lookupQuestion.nextEntry( group, to.params.question );

	if ( ! question ) {
		return {
			name: to.meta.final,
		};
	}

	return {
		name: `${ type }.question`,
		params: {
			group,
			question: question.name,
		},
	};
}

const router = new VueRouter( {
	base: BASE_URL,
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'home',
			component: TheHomeScreen,
		},
		{
			path: '*',
			redirect: '/',
		},
		{
			path: '/profile',
			name: 'profile.firstQuestion',
			meta: {
				group: 'profile',
			},
			redirect: redirectToFirstQuestion,
		},
		{
			path: '/profile/:question',
			name: 'profile.question',
			component: QuestionScreen,
			meta: {
				group: 'profile',
			},
			props: getQuestionProps,
		},
		{
			path: '/profile/:question/next',
			name: 'profile.nextQuestion',
			meta: {
				group: 'profile',
				final: 'activities',
			},
			redirect: redirectToNextQuestion,
		},
		{
			path: '/activities',
			name: 'activities',
			component: QuestionScreen,
			props() {
				return {
					type: 'activities',
					group: 'app',
					question: {
						name: 'activity',
						title: 'What activity are you doing?',
						poster: 'shopping',
						color: 'yellow',
						type: 'activities',
						config: {
							options: activities,
							optionGroups: activity_groups,
						},
					},
					progress: {
						type: 'activity',
						current: 1,
						total: Math.max( ...activities.map( a => a.questions.length ) ),
					},
				};
			},
		},
		{
			path: '/activity/:group/',
			name: 'activity.firstQuestion',
			meta: {
				type: 'activity',
			},
			redirect: redirectToFirstQuestion,
		},
		{
			path: '/activity/:group/:question',
			name: 'activity.question',
			component: QuestionScreen,
			meta: {
				type: 'activity',
			},
			props: getQuestionProps,
		},
		{
			path: '/activity/:group/:question/next',
			name: 'activity.nextQuestion',
			meta: {
				type: 'activity',
				final: 'results',
			},
			redirect: redirectToNextQuestion,
		},
		{
			path: '/results',
			name: 'results',
			component: TheResultScreen,
		},
		{
			path: '/end',
			name: 'end',
			component: TheEndScreen,
		},
		{
			path: '/guidance',
			name: 'guidance',
			component: TheGuidanceScreen,
		},
		{
			path: '/mockup',
			name: 'mockup',
			component: TheMockup,
		},
	],
} );

router.beforeEach( ( to, from, next ) => {
	if ( ! [ 'home', 'mockup', 'guidance' ].includes( to.name ) && ! store.state.acceptMainDisclaimer ) {
		next( '/' );
		return;
	}

	next();
} );

export default router;
